<template>
  <div>
    <!-- button -->
    <div class="button">
      <el-button class="add">
        <i class="el-icon-plus"></i>
        Add category
      </el-button>
      <el-button class="add">
        <i class="el-icon-plus"></i>
        Add category
      </el-button>
      <el-button class="add">
        <i class="el-icon-plus"></i>
        Add category
      </el-button>
    </div>
    <!-- tabs -->
    <div class="tabs">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <!-- category -->
        <el-tab-pane label="category" name="category">
          <div class="category">
            <!-- heads button -->
            <div class="heads">
              <el-button class="button">
                <img src="@/assets/Vector.png" />
                <span>Select all</span>
              </el-button>
              <el-button class="button">
                <span>Select all</span>
              </el-button>
            </div>
            <!-- title -->
            <el-card class="title mt">
              <el-row>
                <el-col :span="6" :offset="3">
                  <p class="name">name</p>
                </el-col>
                <el-col :span="4">
                  <p>selling price</p>
                </el-col>
                <el-col :span="5">
                  <p>Creation time</p>
                </el-col>
                <el-col :span="3">
                  <p>Status</p>
                </el-col>
                <el-col :span="3">
                  <p>operation</p>
                </el-col>
              </el-row>
            </el-card>
            <!-- main -->
            <el-card class="mt">
              <el-row>
                <el-col :span="9">
                  <el-table
                    ref="multipleTable"
                    tooltip-effect="dark"
                    style="width: 100%"
                  >
                    <el-table-column type="selection" width="55">
                    </el-table-column>
                  </el-table>
                </el-col>
                <el-col :span="4"></el-col>
                <el-col :span="5"></el-col>
                <el-col :span="3"></el-col>
                <el-col :span="3"></el-col>
              </el-row>
            </el-card>
          </div>
        </el-tab-pane>
        <!-- brand -->
        <el-tab-pane label="brand" name="brand">brand</el-tab-pane>
        <!-- Order type -->
        <el-tab-pane label="Order type" name="Order type"
          >Order type</el-tab-pane
        >
      </el-tabs>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeName: "category",
    };
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
  },
};
</script>

<style scoped>
.button .add {
  background: #ff0a0a;
  color: #fff;
  width: 143px;
  height: 39px;
  font-size: 13px;
  margin-right: 25px;
  margin-left: 20px;
}
.button i {
  margin-right: 7px;
}
.tabs {
  margin-top: 30px;
  margin-left: 20px;
}
.heads .button {
  font-size: 13px;
  width: 105px;
  height: 38px;
  line-height: 18px;
}
.heads .button img {
  width: 17px;
  height: 17px;
  vertical-align: middle;
  margin-right: 2px;
  margin-left: -5px;
}
.title {
  background: #ffdcbc;
  font-size: 13px;
}
</style>